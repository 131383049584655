<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('lead.leadSource')" class="px-5 py-3">
    <div class="lead--source-form-container">
      <form @submit.prevent="submit">
        <div class="form--group row">
          <label class="col-12 col-lg-4">Dari Katalog Agen?</label>
          <div class="col-12 col-lg-8">
            <v-btn
              type="button"
              color="success"
              :class="{ 'v-btn--disabled': !fromCatalogAgent }"
              @click="fromCatalogAgent = true"
            >
              {{ $t('general.yes') }}
            </v-btn>
            <v-btn
              type="button"
              color="error"
              :class="{ 'v-btn--disabled': fromCatalogAgent }"
              @click="fromCatalogAgent = false"
            >
              {{ $t('general.no') }}
            </v-btn>
          </div>
        </div>
        <div class="form--group row" v-if="fromCatalogAgent">
          <label class="col-12 col-lg-4">Sumber Katalog Agen</label>
          <div class="col-12 col-lg-8">
            <multiselect
              name="selectedCatalogAgent"
              id="selectedCatalogAgentField"
              v-model="selectedCatalogAgent"
              :options="agents"
              :searchable="true"
              :close-on-select="true"
              track-by="id"
              label="name"
            />
          </div>
        </div>
        <div
          class="form--group row"
          :class="{ 'has-error': validation.hasError('selectedSource') }"
        >
          <label class="col-12 col-lg-4" for="selectedSourceField">Sumber</label>
          <div class="col-12 col-lg-8">
            <multiselect
              name="selectedSource"
              id="selectedSourceField"
              v-model="selectedSource"
              :options="sources"
              :searchable="true"
              :close-on-select="true"
            />
            <span class="val-error">{{ validation.firstError('selectedSource') }}</span>
          </div>
        </div>
        <div class="d-flex align-center justify-center">
          <v-btn color="primary" class="bottom--button" type="submit">
            {{ $t('lead.btn.saveSource') }}
          </v-btn>
        </div>
      </form>
    </div>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  mixins: [HelperMixin],
  props: [],
  components: { Multiselect },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
      agents: (state) => state.agent.agents,
      sources: (state) => state.lead.form.sources,
    }),
    fromCatalogAgent: {
      get() {
        return this.$store.state.lead.form.fromCatalogAgent;
      },
      set(value) {
        this.$store.commit('lead/form/SET_FROM_CATALOG_AGENT', value);
      },
    },
    selectedCatalogAgent: {
      get() {
        return this.$store.state.lead.form.catalogAgent;
      },
      set(value) {
        this.$store.commit('lead/form/SET_CATALOG_AGENT', value);
      },
    },
    selectedSource: {
      get() {
        return this.$store.state.lead.form.source;
      },
      set(value) {
        this.$store.commit('lead/form/SET_SOURCE', value);
      },
    },
  },
  validators: {
    selectedSource(value) {
      return Validator.value(value).required(this.$i18n.t('errors.lead.source.required'));
    },
  },
  methods: {
    async submit() {
      if (await this.$validate()) {
        await this.$store.dispatch('lead/form/postData');
        await this.$store.dispatch('lead/form/fetchBaseData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('lead.publishSource.successMsg'),
          'success',
        );
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
